



















































































.w-stepper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 0;
  border-bottom: 1px solid #e0e0e0; /*#aea9a9;*/
  padding: 25px 0 30px;
  margin-bottom: 30px;
  & .circle {
    cursor: pointer;
  }
}
.circle {
  width: 16px;
  height: 16px;
  justify-self: start;
  border-radius: 100%;
  border-width: 2px;
  border-color: #e61e62;
  &.past {
    background-color: #e61e62;
  }
  &.future {
    border: #e0e0e0;
    background-color: #e0e0e0;
  }
}
.line {
  height: 2px;
  align-self: center;
  flex-grow: 1;
  background-color: #e0e0e0;
  &.past {
    background-color: #e61e62;
  }
  &.future {
    background-color: #e0e0e0;
  }
}
